import './styles.scss';

import React from 'react';

export const Footer: React.FC = () => {
    return (
        <div className='footer'>
            <ul>
                <li>©Entelegen 2025</li>
            </ul>
        </div>
    );
};
