import React from 'react';

import { Achievement, CareerCard, /*ClientCard,*/ Contacts, Expertise, LandingCard, Product, Services } from '../components';

export const LandingPage: React.FC = () => {
    return (
        <>
            <LandingCard />
            <Services />
            <Expertise />
            {/* <ClientCard /> */}
            <Product />
            <Achievement />
            <CareerCard />
            <Contacts />
        </>
    );
};
