import './styles.scss';
import 'react-multi-carousel/lib/styles.css';

import React from 'react';
import Carousel from 'react-multi-carousel';

// import econnectone from '../../../../assets/images/product/dashboard.png';
import econnectone from '../../../../assets/images/product/dashboard.png';
import econnectthree from '../../../../assets/images/product/econnectattendance.png';
import econnectfour from '../../../../assets/images/product/econnectdisplay.png';
import econnecttwo from '../../../../assets/images/product/econnectlogin.png';
import { Arrows } from '../../../Arrows';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
export const EconnectApplicationKnowMore: React.FC = () => {
    const img = [econnectone, econnecttwo, econnectthree, econnectfour];
    return (
        <div className='econnectapplication-knowmore'>
            <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                keyBoardControl={true}
                autoPlaySpeed={3000}
                removeArrowOnDeviceType='mobile'
                customLeftArrow={<Arrows type='left' />}
                customRightArrow={<Arrows type='right' />}
                containerClass='container-class'
                itemClass='item-class'
                className='carousel'
            >
                {img.map((items, index) => (
                    <div key={index}>
                        <img src={items} />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
